<template>
    <div>
        <b-card-group deck>
            <b-card class="mb-2">
                <b-card-body>
                    <b-table hover striped small stacked="md" :fields="fields" :items="tableData">
                        <template v-slot:thead-top="data">
                            <tr>
                                <th colspan="6">
                                    <b-row>
                                        <b-col>
                                            <b-select size="sm"
                                                      v-model="selectedGeneration"
                                                      :options="generationOptions"
                                                      @change="refreshTableAndCategoryData" />
                                        </b-col>
                                        <b-col>
                                            <b-select size="sm"
                                                      v-model="selectedCategory"
                                                      :options="ojtCategoryOptions"
                                                      @change="refreshTableData" />
                                        </b-col>
                                        <b-col>
                                            <b-button class="float-right"
                                                      v-b-modal.add-ojt-modal
                                                      size="sm"
                                                      variant="dark">Add New</b-button>
                                        </b-col>
                                    </b-row>
                                </th>
                            </tr>
                        </template>
                        <template v-slot:cell(displayId)="row">
                            <div
                                    class="display-id"
                                    v-if="!row.item.editMode">
                                {{row.item.displayId}}
                            </div>
                        </template>
                        <template v-slot:cell(description)="row">
                            <div v-if="!row.item.editMode">
                                {{row.item.description}}
                            </div>
                        </template>
                        <template v-slot:cell(buttons)="row">
                            <div v-if="!row.item.editMode">
                                <b-link
                                        class="more-info"
                                        :disabled="!row.item.url"
                                        :href="row.item.formattedUrl"
                                        target="_blank">more info</b-link> |
                                <b-link
                                        @click="startEditing(row)">edit</b-link>
                            </div>
                        </template>
                        <template v-slot:row-details="row">
                            <b-card-group deck>
                                <b-card border-variant="primary"
                                        header-bg-variant="primary"
                                        header-text-variant="white"
                                        :header="'Edit '+row.item.displayId">
                                    <b-row>
                                        <b-col class="col-md-2">
                                            <b-form-group
                                                    label="Display ID:"
                                                    :label-for="'edit-display-id-'+row.item.id"
                                                    size="sm"
                                                    :state="errorState(row.item, 'displayId')"
                                                    invalid-feedback="Required - display ID cannot be longer than 10 characters including prefix">
                                                <display-id
                                                        prefix="OJT"
                                                        :id="'edit-display-id-'+row.item.id"
                                                        v-model="row.item.workspace.displayId"
                                                        size="sm"
                                                        :state="errorState(row.item, 'displayId')" />
                                            </b-form-group>
                                        </b-col>
                                        <b-col class="col-md-5">
                                            <b-card border-variant="danger"
                                                    header-bg-variant="danger"
                                                    header-text-variant="white"
                                                    header="Category">
                                                <!--Prefer category change warning over field validation-->
                                                <b-form-group
                                                        size="sm"
                                                        :state="false"
                                                        :invalid-feedback="dangerZoneWarning">
                                                    <OJTCategorySelect
                                                            size="sm"
                                                            variant="danger"
                                                            v-model="row.item.workspace.categoryId"
                                                            @input="setTitleAsCategoryName(row.item.workspace)"
                                                            :generation="row.item.workspace.generation"
                                                            :showFirst="false" />
                                                </b-form-group>
                                            </b-card>
                                        </b-col>
                                        <b-col class="col-md-5">
                                            <b-form-group
                                                    label="Description:"
                                                    :label-for="'edit-desc-'+row.item.id"
                                                    size="sm"
                                                    :state="errorState(row.item, 'description')"
                                                    invalid-feedback="Required">
                                                <b-textarea
                                                        :id="'edit-desc-'+row.item.id"
                                                        v-model="row.item.workspace.description"
                                                        size="sm"
                                                        :state="errorState(row.item, 'description')" />
                                            </b-form-group>
                                            <b-form-group
                                                    label="Recommended Time:"
                                                    :label-for="'edit-rec-time-'+row.item.id">
                                                <b-input
                                                        :id="'edit-rec-time-'+row.item.id"
                                                        v-model="row.item.workspace.recommendedTime"
                                                        size="sm"/>
                                            </b-form-group>
                                            <b-form-group
                                                    label="URL:"
                                                    :label-for="'edit-url-'+row.item.id">
                                                <!--TODO - standardize and validate URL format-->
<!--                                                <b-input-group-->
<!--                                                        :prepend="'http://'"-->
<!--                                                        size="sm">-->
                                                    <b-input
                                                            :id="'edit-url-'+row.item.id"
                                                            v-model="row.item.workspace.url"
                                                            size="sm" />
<!--                                                    <b-input-group-addon>-->
<!--                                                        <b-button-->
<!--                                                                size="sm"-->
<!--                                                                :disabled="!row.item.workspace.url"-->
<!--                                                                @click="checkUrl(row.item.workspace.url)">Check</b-button>-->
<!--                                                    </b-input-group-addon>-->
<!--                                                </b-input-group>-->
                                            </b-form-group>
                                            <div class="clearfix">
                                                <b-button-group
                                                        size="sm"
                                                        class="float-right">
                                                    <b-button
                                                            variant="primary"
                                                            @click="cancelEdit(row)">Cancel</b-button>
                                                    <b-button
                                                            variant="success"
                                                            :disabled="allowSave(row.item)"
                                                            @click="saveEdit(row)">Save</b-button>
                                                </b-button-group>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-card>
                            </b-card-group>
                        </template>
                    </b-table>
                </b-card-body>
            </b-card>
        </b-card-group>
        <b-modal id="add-ojt-modal"
                 title="Add New On-the-Job Training"
                 header-bg-variant="dark"
                 header-text-variant="white"
                 scrollable>
            <b-card>
                <b-form-group
                        label="Category:"
                        label-for="new-ojt-category"
                        size="sm"
                        :state="errorState(newOJT, 'category')"
                        invalid-feedback="Required">
                    <OJTCategorySelect
                            variant="danger"
                            v-model="newOJT.workspace.categoryId"
                            @input="setTitleAsCategoryName(newOJT.workspace)"
                            :generation="newOJT.workspace.generation"
                            size="sm"
                            :state="errorState(newOJT, 'category')"
                            :showFirst="true" />
                </b-form-group>
                <b-form-group
                        label="Display ID:"
                        label-for="new-ojt-display-id"
                        size="sm"
                        :state="errorState(newOJT, 'displayId')"
                        invalid-feedback="Required - display ID cannot be longer than 10 characters including prefix">
                    <display-id
                            id="new-ojt-display-id"
                            prefix="OJT"
                            v-model="newOJT.workspace.displayId"
                            size="sm"
                            :state="errorState(newOJT, 'displayId')" />
                </b-form-group>
                <b-form-group
                        label="Description:"
                        label-for="new-ojt-description"
                        size="sm"
                        :state="errorState(newOJT, 'description')"
                        invalid-feedback="Required">
                    <b-form-textarea
                            id="new-ojt-description"
                            v-model="newOJT.workspace.description"
                            size="sm"
                            :state="errorState(newOJT, 'description')" />
                </b-form-group>
                <b-form-group
                        label="Recommended Time:"
                        label-for="new-ojt-recommended-time"
                        size="sm">
                    <b-form-input
                            id="new-ojt-recommended-time"
                            size="sm"
                            v-model="newOJT.workspace.recommendedTime" />
                </b-form-group>
                <b-form-group
                        label="URL:"
                        label-for="new-ojt-url"
                        size="sm">
                    <b-form-input
                            id="new-ojt-url"
                            size="sm"
                            v-model="newOJT.workspace.url" />
                </b-form-group>
            </b-card>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-button-group size="sm">
                    <b-button
                            variant="primary"
                            @click="hide">Cancel</b-button>
                    <b-button
                            variant="success"
                            :disabled="allowSave(newOJT)"
                            @click="saveEdit(newOJT)">Save</b-button>
                </b-button-group>
            </template>
        </b-modal>
    </div>
</template>
<script>
import {Component, Vue} from 'vue-property-decorator';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import store from '@/store/store';
import {OJT, NULL_OJT} from '@/model/activity';
import _ from 'underscore';
import DisplayId from '@/views/private/activities/DisplayId.vue';
import OJTCategorySelect from '@/views/private/activities/OJTCategorySelect.vue';
import {sprintf} from 'sprintf-js';
import activityDao from '@/dao/activity_dao';
import {ALL_OJT_CATEGORY} from "../../../model/activity";
import {errorModalOptions, errorToastOptions} from "../../../util/formatters";

@Component({
    components: {
        DisplayId,
        OJTCategorySelect
    },
    asyncComputed: {
        tableData: {
            async get() {
                try {
                    await store.dispatch('activities/loadOJTs');
                    const ojts = this.$store.getters['activities/getOJTs'];
                    const filteredOjts = _.chain(ojts)
                        .filter(ojt => {
                            const categoryId = this.selectedCategory || this.allCategory;
                            if (_.isEqual(categoryId, this.allCategory.id)) {
                                return true;
                            }
                            else {
                                return _.isEqual(ojt.categoryId, categoryId);
                            }
                        })
                        .filter(ojt => {
                            const generation = this.selectedGeneration || this.currentGeneration;
                            return _.isEqual(ojt.generation, generation);
                        })
                        .sortBy(ojt => {
                            const ojtDisplayId = ojt.displayId.substring(ojt.displayId.indexOf('-') + 1);
                            return Number(ojtDisplayId);
                        })
                        .value();
                    return filteredOjts;
                }
                catch (error) {
                    this.$bvToast.toast(error.message, errorToastOptions);
                }
            }
        }
    },
    methods: {
        refreshTableData() {
            this.$asyncComputed.tableData.update();
        },
        refreshTableAndCategoryData() {
            this.$asyncComputed.tableData.update();
            this.resetCategoryFilter();
        }
    }
})

export default class OJTPage extends Vue {
    selectedCategory = this.allCategory.id;
    selectedGeneration = this.currentGeneration;
    newOJT = NULL_OJT.clone();

    setTitleAsCategoryName(ojt) {
        const category = this.$store.getters['activities/getOJTCategoryById'](ojt.categoryId);
        ojt.title = _.isUndefined(category) ? undefined : category.name;
    }

    resetNewOJT() {
        this.newOJT = NULL_OJT.clone();
        this.newOJT.workspace.generation = this.currentGeneration;
    }

    errorState(ojt, field) {
        return ojt.workspace.ojtErrors[field];
    }

    allowSave(ojt) {
        return _.any(ojt.requiredFields, fieldName => {
            return !this.errorState(ojt, fieldName);
        });
    }

    resetCategoryFilter() {
        if (!_.isEqual(this.selectedCategory.generation, this.selectedGeneration)) {
            this.selectedCategory = this.allCategory.id;
        }
    }

    //b-form-select will not allow asyncComputed source
    get ojtCategoryOptions() {
        const categories = this.$store.getters['activities/getOJTCategories'];
        const filteredCategories = _.chain(categories)
            .filter(cat => {
                const generation = this.selectedGeneration || this.currentGeneration;
                return _.isEqual(cat.generation, generation);
            })
            .map(cat => {
                return {
                    value: cat.id,
                    text: sprintf('%d - %s (%d)', cat.id, cat.name, this.countOJTsUnderCategory(cat))
                };
            })
            .sortBy(cat => {
                return Number(cat.value);
            })
            .value();
        //Add "all categories" option
        const all = {
            value: this.allCategory.id,
            text: sprintf('%s (%d)', this.allCategory.name, this.countOJTsUnderCategory(this.allCategory))
        };
        filteredCategories.unshift(all);
        return filteredCategories;
    }

    get allCategory() {
        const allCategory = ALL_OJT_CATEGORY.clone();
        allCategory.generation = this.selectedGeneration || this.currentGeneration;
        return allCategory;
    }

    get currentGeneration() {
        const currentGeneration = store.getters['activities/currentActivityGeneration'];
        return currentGeneration;
    }

    dangerZoneWarning = `
        Required.
        Changing this value will impact the schedule of every trainee linked to this OJT.
        It will also hide this OJT from this view.
        You will need to select the right Category/Generation above to see it again.`.trim();

    get generationOptions() {
        return this.$store.getters['activities/activityGenerations'];
    }

    countOJTsUnderCategory(category) {
        const ojts = this.$store.getters['activities/getOJTsForCategory'](category);
        return _.isEmpty(ojts) ? 0 : ojts.length;
    }

    get fields() {
        return [{
            key: 'displayId',
            label: 'ID',
            sortable: true
        }, {
            key: 'title',
            label: 'Title',
            sortable: false
        }, {
            key: 'description',
            label: 'Description',
            sortable: false
        }, {
            key: 'recommendedTime',
            label: 'Recommended Time',
            sortable: true
        }, {
            key: 'buttons',
            label: '',
            sortable: false
        }];
    }

    async beforeRouteEnter(to, from, next) {
        store.commit('addBreadcrumb', Breadcrumb.create('On the Job Training', null, true));
        try {
            await store.dispatch('activities/loadOJTCategories');
            await store.dispatch('activities/loadOJTs');
        }
        catch (error) {
            const vm = new Vue();
            vm.$bvToast.toast(error.message, errorToastOptions);
        }
        next();
    }

    mounted() {
        this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
            if ('add-ojt-modal' === modalId) {
                this.resetNewOJT();
            }
        });
    }

    startEditing(row) {
        row.item.editMode = true;
        row.toggleDetails();
    }

    async saveEdit(row) {
        const ojt = row instanceof OJT ? row : row.item;
        try {
            const saved = await activityDao.saveOJT(ojt.workspace);

            ojt.workspace.category = this.$store.getters['activities/getOJTCategoryById'](ojt.workspace.categoryId);
            ojt.commit();

            if (ojt.isNew) {
                // We added a new OJT, we need to add it to the table

                // Add the OJT to the store
                this.$store.commit('activities/addOJT', saved);

                // Hide the modal
                this.$bvModal.hide('add-ojt-modal');
            }

            if (_.isFunction(row.toggleDetails)) {
                row.toggleDetails();
            }
            this.resetNewOJT();
            this.refreshTableData();
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
            if (ojt.isNew) {
                this.resetNewOJT();
            }
            else {
                ojt.rollback();
            }
        }
    }

    cancelEdit(row) {
        row.item.rollback();
        row.toggleDetails();
    }
}
</script>
<style scoped>
    a.more-info.disabled {
        color: #888;
    }
    a.more-info.disabled:hover {
        text-decoration: none;
        cursor: default;
    }
    div.display-id {
        white-space: nowrap;
    }
</style>
