<template>
    <b-select v-model="id" :options="options" :size="size" :state="inputState">
        <template v-slot:first v-if="showFirst">
            <option :value="null"> - </option>
        </template>
    </b-select>
</template>
<script>
import {Component, Vue, Prop} from 'vue-property-decorator';
import _ from 'underscore';
import store from '@/store/store';

@Component
export default class OJTCategorySelect extends Vue {

    @Prop({type: Number}) value;
    @Prop({type: String}) size;
    @Prop({type: Boolean}) showFirst;
    @Prop({type: Number}) generation;
    @Prop({type: Boolean, default: false}) showState;

    get id() {
        return this.value;
    }

    set id(value) {
        this.$emit('input', value);
    }

    get options() {
        const categories = this.$store.getters['activities/getOJTCategories'];

        return _.chain(categories)
            .filter((c) => c.generation === this.generation)
            .sortBy((c) => c.ordinal)
            .map((c) => {
                return {
                    value: c.id,
                    text: c.name
                };
            })
            .value();
    }

    get inputState() {
        return this.showState ? _.isNumber(this.id) : null;
    }
}
</script>
<style scoped>

</style>
